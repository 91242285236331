// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the theme object.
@import 'm3-theme';

$beat-theme: mat.define-theme((color: (theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
      density: (scale: 0,
      )));

$primary: map-get($beat-theme, primary);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($beat-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($beat-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($beat-theme);
/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// .background-primary-color-20 {
//   background-color: mat.get-theme-color($beat-theme,primary,20) !important;
//   color: white !important;
// }

// .background-primary-color-30 {
//   background-color: mat.get-theme-color($beat-theme,primary,40) !important;
//   color: white !important;
// }

.mat-toolbar {
  background-color: mat.get-theme-color($beat-theme, primary, 40) !important;
  color: white !important;
}

.mat-sidenav {
  background-color: mat.get-theme-color($beat-theme, primary, 20) !important;

  .mdc-list-item__primary-text {
    color: white;
  }
}